<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 mb-n4">

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Badge</div>
                    <div class="card-body">
                        <div>
                            <span class="badge badge-primary">Badge</span>
                            <span class="badge badge-secondary ml-2">Badge</span>
                            <span class="badge badge-success ml-2">Badge</span>
                            <span class="badge badge-danger ml-2">Badge</span>
                            <span class="badge badge-warning ml-2">Badge</span>
                            <span class="badge badge-info ml-2">Badge</span>
                            <span class="badge badge-light ml-2">Badge</span>
                            <span class="badge badge-dark ml-2">Badge</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Badge / Outline</div>
                    <div class="card-body">
                        <div>
                            <span class="badge badge-outline-primary">Badge</span>
                            <span class="badge badge-outline-secondary ml-2">Badge</span>
                            <span class="badge badge-outline-success ml-2">Badge</span>
                            <span class="badge badge-outline-danger ml-2">Badge</span>
                            <span class="badge badge-outline-warning ml-2">Badge</span>
                            <span class="badge badge-outline-info ml-2">Badge</span>
                            <span class="badge badge-outline-light ml-2">Badge</span>
                            <span class="badge badge-outline-dark ml-2">Badge</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Badge / Pill</div>
                    <div class="card-body">
                        <div>
                            <span class="badge badge-pill badge-primary">Pill Badge</span>
                            <span class="badge badge-pill badge-secondary ml-2">Pill Badge</span>
                            <span class="badge badge-pill badge-success ml-2">Pill Badge</span>
                            <span class="badge badge-pill badge-danger ml-2">Pill Badge</span>
                            <span class="badge badge-pill badge-warning ml-2">Pill Badge</span>
                            <span class="badge badge-pill badge-info ml-2">Pill Badge</span>
                            <span class="badge badge-pill badge-light ml-2">Pill Badge</span>
                            <span class="badge badge-pill badge-dark ml-2">Pill Badge</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Badges',
}
</script>
